<template>
  <v-card class="pt-0 pb-2">
    <v-card-title class="pa-0 mt-0" v-if="crm">
      <v-spacer />

      <v-btn icon @click="chiudi">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-title>

    <div style="height: 150px">
      <v-img :src="getImg" aspect-ratio="3.75" v-show="attesaCaricamento == false"></v-img>
    </div>

    <v-card color="#6B6B6B" class="mx-2">
      <v-card-title primary-title style="height: 100px">
        <div v-if="attesaCaricamento == false" style="text-align: left">
          <div class="headline mb-0">
            Vuoi confermare l'ordine:
            <span class="white--text">{{ dati.ordID }} </span> ?
          </div>
          <div class="subheading" v-if="dati.custOrd">
            Vostro ordine : {{ dati.custOrd }}
          </div>
          <div class="subheading">Riferimento : {{ dati.ordRef }}</div>
        </div>

        <v-progress-circular :size="50" color="black" indeterminate style="margin: auto" v-if="attesaCaricamento" />
      </v-card-title>

      <v-card-actions class="mt-3">
        <div style="margin: auto;" v-show="showBtn">
          <v-btn dark color="primary" @click="conferma(true)" :disabled="attesaCaricamento" class="mr-2">Si</v-btn>
          <v-btn dark color="primary" @click="annulla()" :disabled="attesaCaricamento">Chiudi</v-btn>
        </div>
      </v-card-actions>

      <v-alert :value="!!message" :type="messageType" v-html="message"> </v-alert>
    </v-card>
  </v-card>
</template>

<script>
import { get_datiConfermaOrdine, confermaOrdine } from "@/api/api.ext.js";

export default {
  props: {
    crm: { type: Boolean, required: false, default: false },
    factoryId: { type: Number, required: true, default: 0 },
    versione: { type: String, required: true, default: "0" },
    docId: { type: String, required: true, default: "" },
    codCf: { type: String, required: true, default: "" },
    dialog: { type: Boolean, required: false, default: false },
  },

  data: () => ({
    dati: {},

    attesaCaricamento: false,
    message: "",
    messageType: "success",
    showBtn: false,
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.dati = {};
      this.messageType = "success";
      this.message = "";
      this.showBtn = false;

      if (this.docId.length == 0) return;

      this.attesaCaricamento = true;

      try {
        let res = await get_datiConfermaOrdine(
          this.factoryId + "/" + this.docId
        );

        this.dati = res.data;

        this.message = this.dati.confMessage;

        if (this.dati.confMessageStatus == 1) this.messageType = "success";
        else if (this.dati.confMessageStatus == 2) this.messageType = "error";
        else if (this.dati.confMessageStatus == 3) this.messageType = "warning";

        this.showBtn = this.dati.confShowBtn;
      } catch (error) {
        console.log(error);
      }

      this.attesaCaricamento = false;
    },

    async conferma(confermato) {
      this.attesaCaricamento = true;

      try {
        let res = await confermaOrdine({
          IDFactory: this.factoryId,
          IDBrand: this.dati.idBrand,
          OrdID: this.docId,
          LinkSrc: this.getSorgente(),
          Confermato: confermato,
          ConfMessageStatus: this.dati.confMessageStatus,
          Versione: this.versione,
          CodCf: this.codCf,
        });
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaCaricamento = false;

      // Ricarico i dati
      this.caricaDati();

      // Dati parent
      this.$emit("confermato", confermato);
    },

    async annulla() {
      /* let res = await this.$confirm.open(
        "Conferma",
        "Vuoi rendere il tuo ordine <br>'non confermabile' ? <br><br>Il tuo ordine sarà nuovamente attivo per la conferma quando verranno inviate ed apportate le eventuali modifiche" +
        "<br><br>Se invece vuoi uscire senza apportare modifiche allo stato dell'ordine, è sufficiente ignorare il messaggio e chiudere la scheda del browser"
      );

      if (res == false) return;

      // Conferma no
      this.conferma(false); */
      this.chiudi();
    },

    chiudi() {
      this.$emit("chiudi");
    },

    getSorgente() {
      return this.crm ? "CRM" : "QR";
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getImg() {
      if (!!this.dati == false || Object.keys(this.dati).length == 0) return;

      return this.$load.getUrlImg() + "/" + this.dati.logoImage;
    },
  },

  watch: {
    dialog() {
      if (this.dialog) this.caricaDati();
    },
  },
};
</script>